<template>
  <div ref="rootRef" class="p-slider" @mousedown="handleMouseDown" @touchstart="handleMouseDown">
    <span
      v-for="index in stepCount"
      :key="index"
      :class="[
        'p-slider__bar',
        isKnobColor
          ? knobColorChange(index) && 'p-slider__bar--knobColor'
          : pointColorChange(index),
      ]"
    />
    <div
      v-if="step === 25"
      class="p-slider__bar--fill"
      :style="{ width: `${normalizedPercentage}%` }"
    >
      <span v-show="infoShow" class="p-slider__info" v-text="`${modelValue}%`" />
    </div>
    <span v-else :class="['p-slider__fill', barClass]" :style="calcStyles" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  step: {
    type: Number,
    required: false,
    default: 25,
  },
  modelValue: {
    type: Number,
    required: true,
  },
  range: {
    type: Array as unknown as PropType<[number, number]>,
    required: false,
    default: () => [0, 100],
  },
})

const emit = defineEmits(['update:modelValue'])

const rootRef = ref()
const infoShow = ref(false)
const isKnobColor = computed(() => props.step === 25)

const rangeToPercentage = (value: number) => {
  const [min, max] = props.range
  return ((value - min) / (max - min)) * 100
}

const percentageToRange = (percentage: number) => {
  const [min, max] = props.range
  return (percentage * (max - min)) / 100 + min
}

const normalizedPercentage = computed(() => rangeToPercentage(props.modelValue))

const stepCount = computed(() => {
  const [min, max] = props.range
  return Math.ceil((max - min) / props.step)
})

const knobColorChange = (index: number) => {
  const [min, max] = props.range
  const totalRange = max - min
  const stepCount = Math.ceil(totalRange / props.step)
  const dynamicStepValue = totalRange / stepCount
  const value = min + (index - 1) * dynamicStepValue
  return value <= props.modelValue
}

const pointColorChange = (index: number) => {
  const [min, max] = props.range
  const totalRange = max - min
  const stepCount = Math.ceil(totalRange / props.step)
  const dynamicStepValue = totalRange / stepCount
  const currentStepValue = min + (index - 1) * dynamicStepValue

  if (props.modelValue > 0 && currentStepValue >= 0 && currentStepValue <= props.modelValue) {
    return 'p-slider__bar--positive'
  }

  if (props.modelValue < 0 && currentStepValue <= 0 && currentStepValue > props.modelValue) {
    return 'p-slider__bar--negative'
  }

  return ''
}

const calcStyles = computed(() => {
  const offset = Math.abs(props.modelValue)
  return { width: `${offset * 2}%` }
})

const barClass = computed(() => {
  return props.modelValue < 0 ? 'p-slider__fill--negative' : 'p-slider__fill--positive'
})

const getPercentage = (e: MouseEvent | TouchEvent) => {
  e.preventDefault()
  const clickedX =
    (e instanceof MouseEvent ? e.clientX : e.touches[0].clientX) -
    rootRef.value.getBoundingClientRect().left
  const percentageCalculate = Math.ceil((clickedX / rootRef.value.offsetWidth) * 100)

  const newValue = percentageToRange(
    percentageCalculate < 0 ? 0 : percentageCalculate > 100 ? 100 : percentageCalculate,
  )
  emit('update:modelValue', newValue)
}

const handleMouseMove = (e: MouseEvent | TouchEvent) => getPercentage(e)

const handleMouseUp = () => {
  infoShow.value = false
  document.removeEventListener('mousemove', handleMouseMove)
  document.removeEventListener('mouseup', handleMouseUp)
  document.removeEventListener('touchmove', handleMouseMove)
  document.removeEventListener('touchend', handleMouseUp)
}

const handleMouseDown = (e: MouseEvent | TouchEvent) => {
  infoShow.value = true
  getPercentage(e)
  document.addEventListener('mousemove', handleMouseMove)
  document.addEventListener('mouseup', handleMouseUp)
  document.addEventListener('touchmove', handleMouseMove)
  document.addEventListener('touchend', handleMouseUp)
}
</script>
